<template>
  <div class="ApprovalTag">
    <!-- 任务评论 -->
    <van-nav-bar
      :title="`任务《${title}》的${lik}`"
      left-text
      left-arrow
      @click-left="onClickLeft"
      :fixed="true"
    />
    <div class="content">
      <van-search
        v-model="searchVal"
        shape="round"
        :placeholder="'搜索'"
        @search="onSearch"
      />

      <van-list
        v-model="loadingAwait"
        :finished="finishedAwait"
        :finished-text="$t('module.noMore')"
        @load="onLoadAwait"
      >
        <div class="zb_group" v-for="(item, index) in assessList" :key="index">
          <div class="zb_group">
            <van-cell
              v-if="type == '5'"
              class="ass_cell"
              size="large"
              :title="item.stype"
              :label="'催办人:' + item.empname"
              :value="item.yymmdd"
            >
              <template slot="label">
                催办人:{{ item.empname }}<br />
                周期：{{ item.khtimename }}
              </template>
              <template>
                <div>{{ item.yymmdd }}</div>
                <div>被催办人:{{ item.bcb_empname }}</div>
              </template>
            </van-cell>
            <van-cell
              v-else
              class="ass_cell"
              size="large"
              :title="
                type == '3'
                  ? item.comment_memo
                  : type == '4'
                  ? item.finishdesp
                  : ''
              "
              :label="'负责人:' + item.task_empname"
              :value="item.crdate"
            >
              <template slot="label">
                负责人:{{ item.task_empname }}<br />
                周期：{{ item.khtimename }}
              </template>
              <template>
                <div>{{ item.crdate }}</div>
                <div>{{ liker + ':' + item.empname }}</div>
                <div v-if="type == '4'">进度：{{ item.task_progress }}%</div>
                <div v-else v-html="'\u00a0\u00a0'"></div>

                <div class="filelist">
                  <div
                    class="van-form_file_List"
                    @click="preView(val, key, item.fileList)"
                    v-for="(val, key) in item.fileList"
                    :key="key"
                  >
                    <img
                      v-if="fileext.indexOf(val.fileext) > -1"
                      v-lazy="val.url"
                    />
                    <img
                      v-else-if="
                        item.fileext == '.doc' || item.fileext == '.docx'
                      "
                      src="@/assets/img/word.png"
                      alt
                    />
                    <img
                      v-else-if="
                        item.fileext == '.xls' || item.fileext == '.xlsx'
                      "
                      src="@/assets/img/excel2.png"
                      alt
                    />
                    <img
                      v-else-if="
                        item.fileext == '.ppt' || item.fileext == '.pptx'
                      "
                      src="@/assets/img/ppt2.png"
                      alt
                    />

                    <img v-else src="@/assets/img/xmind.png" alt />
                  </div>
                </div>
              </template>
            </van-cell>
          </div>
        </div>
      </van-list>
    </div>
    <div class="footer_btn">
      <van-button type="info" @click="add"
        >发表新的{{ ld ? '意见' : lik }}</van-button
      >
    </div>
    <van-dialog
      v-model="showDia"
      :title="'发表新的' + ld ? '意见' : lik"
      show-cancel-button
      :before-close="closeDia"
    >
      <div style="padding: 10px">
        <template v-if="type == '5'">
          <van-field
            :name="'催办日期'"
            :label="'催办日期'"
            required
            v-model="yymmdd"
            :placeholder="'请选择催办日期'"
            @click="showdatepick()"
          ></van-field>
          <van-field
            :name="'催办类型'"
            :label="'催办类型'"
            required
            v-model="checkmemo"
            :placeholder="'请选择催办类型'"
            @click="comboboxNoispoptreefocus()"
          ></van-field>
          <van-field
            :name="'被催办人'"
            :label="'被催办人'"
            required
            v-model="empname"
            :placeholder="'请选择被催办人'"
            @click="showTree()"
          ></van-field>
          <van-cell class="desc_cell" :value="'备注'" />
          <van-field
            class="desc_field"
            v-model="remark"
            rows="3"
            autosize
            type="textarea"
            :placeholder="'备注'"
          />
        </template>
        <template v-else>
          <van-cell class="desc_cell" :value="(ld ? '意见' : lik) + '内容'" />
          <van-field
            class="desc_field"
            v-model="checkmemo"
            required
            rows="3"
            autosize
            type="textarea"
            :placeholder="lik + '内容'"
          />
          <template v-if="type == '4' && !ld">
            <van-cell class="desc_cell" :value="'任务进度'" />
            <!-- <van-field
              class="desc_field"
              v-model="task_progress"
              type="number"
              :placeholder="'任务进度'"
            /> -->
            <van-slider v-model="task_progress">
              <template #button>
                <div class="custom-button">{{ task_progress }}</div>
              </template>
            </van-slider>
          </template>
          <van-cell class="desc_cell" :title="'附件信息'" />
          <van-uploader
            v-model="file"
            accept="*"
            multiple
            :max-count="15 - file.length"
            :after-read="afterRead"
          >
            <van-button icon="plus" type="info"></van-button>
          </van-uploader>
        </template>
      </div>
    </van-dialog>
    <van-popup v-model="showDtPicker" round position="bottom">
      <van-datetime-picker
        v-model="currentDate"
        :type="'date'"
        :min-date="minDate"
        :max-date="maxDate"
        :title="'催办日期'"
        @confirm="onDtConfirm"
        @cancel="showDtPicker = false"
      />
    </van-popup>
    <van-popup
      v-model="showPicker"
      :close-on-click-overlay="false"
      :close-on-popstate="false"
      round
      position="bottom"
    >
      <van-picker
        :title="'催办类型'"
        show-toolbar
        :columns="choices"
        @confirm="onConfirm"
        @cancel="onCancel"
      />
    </van-popup>
    <!-- 树弹窗 -->
    <van-popup
      class="tree_popup"
      v-model="showTreePop"
      closeable
      close-icon="arrow-left"
      close-icon-position="top-left"
      position="right"
      :style="{ width: '100%', height: '100%' }"
    >
      <van-sticky class="fixed_div">
        <div class="tree_title">
          <span>{{ `请选择被催办人` }}</span>
        </div>
        <div class="tree_search">
          <van-search
            v-model="searchTreeVal"
            placeholder="请输入搜索关键词"
            @search="onSearchTree"
          />
        </div>
        <div class="tree_head">
          <span class="head_start" @click="backTo(true)">开始</span>
          <span
            v-for="(ele, idex) in clickParentTree"
            :key="idex"
            :class="idex == clickParentTree.length - 1 ? 'theLast' : 'noLast'"
            @click="backTo(ele)"
          >
            <span class="division">></span>
            {{ ele.keyname }}
          </span>
        </div>
        <van-divider
          :style="{
            color: '#E8E8E8',
            borderColor: '#E8E8E8',
            margin: '5px 0 0 0'
          }"
        />
      </van-sticky>
      <div class="tree_body">
        <!-- 岗位树和员工树 -->
        <div v-if="whichTree == 'station' || whichTree == 'employee'">
          <!-- 多选树 -->
          <div v-if="multiseltree">
            <!-- 父节点 -->
            <van-cell
              class="parents_cell"
              v-for="(item, index) in parentTreeList"
              :key="index + item.keyname"
              :title="item.keyname"
            >
              <template #default>
                <div
                  :class="item.hasLower ? 'haveClo' : 'noClo'"
                  @click="checkParent(item)"
                >
                  <van-icon name="arrow-down" />
                  <span>下级</span>
                </div>
              </template>
              <template #icon>
                <van-checkbox
                  v-model="item.checked"
                  @click="tickParent(item)"
                  shape="square"
                ></van-checkbox>
              </template>
            </van-cell>
            <!-- 子节点 -->
            <van-cell
              class="childs_cell"
              v-for="(it, idx) in childsTreeList"
              :key="idx"
            >
              <template #title>
                <van-checkbox v-model="it.checked" shape="square">
                  <van-button
                    size="small"
                    :icon="whichTree == 'employee' ? 'user-o' : 'manager-o'"
                    :type="whichTree == 'employee' ? 'warning' : 'primary'"
                  ></van-button>
                  <span class="check_name">{{ it.keyname }}</span>
                </van-checkbox>
              </template>
            </van-cell>
          </div>
          <!-- 非多选 -->
          <div v-else>
            <!-- 父节点 -->
            <van-cell
              class="parents_cell"
              v-for="(item, index) in parentTreeList"
              :key="index + item.keyname"
              :title="item.keyname"
              @click="checkParent(item)"
            >
              <template #default>
                <div :class="item.hasLower ? 'haveClo' : 'noClo'">
                  <van-icon name="arrow-down" />
                  <span>下级</span>
                </div>
              </template>
            </van-cell>
            <!-- 子节点 -->
            <van-cell
              class="childs_cell"
              v-for="(it, idx) in childsTreeList"
              :key="idx"
              @click="checkChild(it)"
            >
              <template #title>
                <van-button
                  size="small"
                  :icon="whichTree == 'employee' ? 'user-o' : 'manager-o'"
                  :type="whichTree == 'employee' ? 'warning' : 'primary'"
                ></van-button>
                <span class="check_name">{{ it.keyname }}</span>
              </template>
            </van-cell>
          </div>
        </div>
        <!-- 其他树 -->
        <div v-else>
          <!-- 多选树 -->
          <div v-if="multiseltree">
            <van-cell
              class="parents_cell other_cell"
              center
              v-for="(item, index) in otherTreeList"
              :key="index + item.keyname"
            >
              <template #icon>
                <van-checkbox
                  v-model="item.checked"
                  @click="tickParent(item)"
                  shape="square"
                ></van-checkbox>
              </template>
              <template #title>
                <van-button
                  size="small"
                  icon="cluster-o"
                  color="#1491f7"
                ></van-button>
                <span class="check_name">{{ item.keyname }}</span>
              </template>
              <template #default>
                <div
                  v-if="item.hasLower"
                  class="haveClo"
                  @click.stop="hasLower(item)"
                >
                  <van-icon name="arrow-down" />
                  <span>下级</span>
                </div>
              </template>
            </van-cell>
          </div>
          <!-- 非多选树 -->
          <div v-else>
            <van-cell
              class="parents_cell other_cell"
              center
              v-for="(item, index) in otherTreeList"
              :key="index + item.keyname"
              @click="checkChild(item)"
            >
              <template #title>
                <van-button
                  size="small"
                  icon="cluster-o"
                  color="#1491f7"
                ></van-button>
                <span class="check_name">{{ item.keyname }}</span>
              </template>
              <template #default>
                <div
                  v-if="item.hasLower"
                  class="haveClo"
                  @click.stop="hasLower(item)"
                >
                  <van-icon name="arrow-down" />
                  <span>下级</span>
                </div>
              </template>
            </van-cell>
          </div>
        </div>
      </div>
      <div class="tree_foot" v-if="multiseltree">
        <van-button type="primary" size="small" @click="cleanChecked"
          >清除</van-button
        >
        <van-button
          type="info"
          size="small"
          :disabled="!canComfirm"
          @click="comfirmChecked"
          >确定</van-button
        >
      </div>
    </van-popup>
  </div>
</template>

<script>
import { Toast, Dialog, ImagePreview, showToast } from 'vant'
import {
  getCommTreeData,
  getGsTaskCommentList,
  addGsTaskCommentRecord,
  getGsTaskReportList,
  reportGsTaskForDb,
  addGsTaskAlarmRecord,
  getGsTaskAlarmList
} from '@api/wxjx.js'
import { upLoadBase64File, getModuleAttFileList, upLoadFile } from '@api/wx.js'
import { parseTime } from '@/utils'
export default {
  data() {
    const userInfo = localStorage.userInfo
      ? JSON.parse(localStorage.userInfo)
      : {}
    return {
      userInfo,
      autoid: this.$route.query.autoid,
      title: this.$route.query.title || '',
      type: this.$route.query.type,
      from: this.$route.query.from,
      ld: this.$route.query.ld, // 领导
      lik:
        this.$route.query.type == '3'
          ? '评论'
          : this.$route.query.type == '4'
          ? '汇报'
          : this.$route.query.type == '5'
          ? '催办'
          : '',
      liker:
        this.$route.query.type == '3'
          ? '评论人'
          : this.$route.query.type == '4'
          ? '汇报人'
          : this.$route.query.type == '5'
          ? '催办人'
          : '',
      isLoadingAwait: false,
      loadingAwait: false,
      finishedAwait: false,
      showDia: false,
      assessList: [],
      file: [],
      searchVal: '',
      checkmemo: '',
      task_progress: null,
      yymmdd: '',
      empid: '',
      empname: '',
      remark: '',
      currentDate: new Date(),
      minDate: this.dd('min'),
      maxDate: this.dd('max'),
      showDtPicker: false,
      choices: [],
      multiseltree: false,
      showPicker: false,
      showTreePop: false,
      whichTree: 'employee',
      allTreeList: [],
      otherTreeList: [],
      clickParentTree: [],
      parentTreeList: [],
      childsTreeList: [],
      searchTreeVal: '',
      fileext: ['.png', '.jpg', '.jpeg', '.bmp']
    }
  },
  watch: {
    searchVal(n) {
      this.onSearch()
    }
  },
  created() {},
  methods: {
    dd(m) {
      //日期控件的最大日期/最小日期
      const d = new Date()
      const year = d.getFullYear()
      const month = d.getMonth()
      const day = d.getDate()
      if (m === 'min') {
        return new Date(year - 100, month, day)
      } else {
        return new Date(year + 30, month, day)
      }
    },
    onSearch() {
      if (this.searchVal) {
        if (this.type == '3') {
          this.assessList = this.assessList.filter(
            item =>
              item.task_empname.indexOf(this.searchVal) > -1 ||
              item.empname.indexOf(this.searchVal) > -1 ||
              item.comment_memo.indexOf(this.searchVal) > -1
          )
        } else if (this.type == '4') {
          this.assessList = this.assessList.filter(
            item =>
              item.task_empname.indexOf(this.searchVal) > -1 ||
              item.empname.indexOf(this.searchVal) > -1 ||
              item.finishdesp.indexOf(this.searchVal) > -1
          )
        } else if (this.type == '5') {
          this.assessList = this.assessList.filter(
            item =>
              item.task_empname.indexOf(this.searchVal) > -1 ||
              item.empname.indexOf(this.searchVal) > -1 ||
              item.finishdesp.indexOf(this.searchVal) > -1
          )
        }
      } else {
        this.getList()
      }
    },
    onClickLeft() {
      this.$router.push(this.from || '/evaTask')
    },
    // 9.1.获取待审批的360考核方案列表
    getList() {
      let _this = this
      if (this.type == '3') {
        getGsTaskCommentList({
          username: this.userInfo.username,
          autoid: this.autoid
        }).then(res => {
          if (res.data.length == 0) {
            _this.finishedAwait = true
            // 无数据
          } else {
            _this.loadingAwait = false
            _this.finishedAwait = true
            res.data.forEach(e => {
              e.fileList = []
              let params = {
                moduleno: 563001,
                mautoid: e.autoid,
                username: this.userInfo.username
              }
              getModuleAttFileList(params).then(res => {
                e.fileList = res.module_atlist
              })
            })
            _this.assessList = res.data

            this.$forceUpdate()
          }
        })
      } else if (this.type == '4') {
        getGsTaskReportList({
          username: this.userInfo.username,
          autoid: this.autoid
        }).then(res => {
          if (res.data.length == 0) {
            _this.finishedAwait = true
            // 无数据
          } else {
            _this.loadingAwait = false
            _this.finishedAwait = true
            res.data.forEach(e => {
              e.fileList = []
              let params = {
                moduleno: 563003,
                mautoid: e.autoid,
                username: this.userInfo.username
              }
              getModuleAttFileList(params).then(res => {
                e.fileList = res.module_atlist
              })
            })
            _this.assessList = res.data

            this.$forceUpdate()
          }
        })
      } else if (this.type == '5') {
        getGsTaskAlarmList({
          username: this.userInfo.username,
          autoid: this.autoid
        }).then(res => {
          if (res.data.length == 0) {
            _this.finishedAwait = true
            // 无数据
          } else {
            _this.loadingAwait = false
            _this.finishedAwait = true
            _this.assessList = res.data

            this.$forceUpdate()
          }
        })
      }
    },
    onLoadAwait() {
      // 异步更新数据
      if (this.isLoadingAwait) {
        this.assessList = []
        this.isLoadingAwait = false
      }
      this.getList()
    },
    onRefreshAwait() {
      this.assessList = []
      this.finishedAwait = false
      this.loadingAwait = true
      this.onLoadAwait()
      // Toast('刷新成功')
    },
    // tab切换事件
    changeAct(name, title) {
      this.onRefreshAwait()
    },
    //
    goToApply(item) {
      this.$router.push({
        path: '/evaTaskApply',
        query: {
          title: item.taskname,
          autoid: item.autoid
        }
      })
    },
    add() {
      this.checkmemo = ''
      this.yymmdd = ''
      this.empid = ''
      this.empname = ''
      this.remark = ''
      this.file = []
      this.task_progress = 0
      this.showDia = true
    },
    // 弹窗关闭回调
    closeDia(action, done) {
      if (action == 'confirm') {
        if (this.checkmemo) {
          console.log(this.checkmemo, this.file)
          if (this.type == '3') {
            this.addGsTaskCommentRecord()
            done()
          } else if (this.type == '4') {
            if (this.task_progress >= 0 && this.task_progress <= 100) {
              this.task_progress >= 0 && this.task_progress <= 100
              this.reportGsTaskForDb()
              done()
            } else {
              Toast.fail('任务进度（0~100）')
              done(false)
            }
          } else if (this.type == '5') {
            if (this.yymmdd && this.empid) {
              this.addGsTaskAlarmRecord()
              done()
            } else if (!this.yymmdd) {
              Toast.fail('催办日期不能为空')
              done(false)
            } else if (!this.empid) {
              Toast.fail('被催办人内容不能为空')
              done(false)
            }
          }
        } else {
          Toast.fail(this.lik + '内容不能为空')
          done(false)
        }
      } else {
        done()
      }
    },
    // 提交评论
    addGsTaskCommentRecord() {
      addGsTaskCommentRecord({
        autoid: this.autoid,
        comment_memo: this.checkmemo,
        remark: this.remark,
        username: this.userInfo.username
      }).then(async res => {
        if (res.iserror === '0') {
          if (res.data[0].info) {
            Toast(res.data[0].info)
          } else {
            Toast.success(this.$t('module.createSuc'))
            if (this.file.length > 0) {
              await this.upLoadBase64File(563001, res.data[0].insid)
              await this.upLoadBase64File(563, this.autoid)
            }
            this.getList()
          }
        }
      })
    },
    // 提交汇报
    reportGsTaskForDb() {
      reportGsTaskForDb({
        autoid: this.autoid,
        finishdesp: this.checkmemo,
        task_progress: this.task_progress,
        remark: '',
        username: this.userInfo.username
      }).then(async res => {
        if (res.iserror === '0') {
          if (res.data[0].info) {
            Toast(res.data[0].info)
          } else {
            Toast.success(this.$t('module.createSuc'))
            if (this.file.length > 0) {
              await this.upLoadBase64File(563003, res.data[0].insid)
              await this.upLoadBase64File(563, this.autoid)
            }
            this.getList()
          }
        }
      })
    },
    // 提交催办
    addGsTaskAlarmRecord() {
      addGsTaskAlarmRecord({
        autoid: this.autoid,
        yymmdd: this.yymmdd,
        stype: this.checkmemo,
        empid: this.empid,
        remark: this.remark,
        username: this.userInfo.username
      }).then(res => {
        if (res.iserror === '0') {
          if (res.data[0].info) {
            Toast(res.data[0].info)
          } else {
            Toast.success(this.$t('module.createSuc'))
            this.getList()
          }
        }
      })
    },

    // 上传附件
    async upLoadBase64File(moduleno, autoid) {
      /*
      任务管理主表：563
      评论子表：563001
      催办子表：563002
      汇报子表：563003
      */
      for (const file of this.file) {
        let n = file.file.type.indexOf('/')
        let fileext = file.file.type.slice(n + 1)
        let fileName = file.file.name
        // let params = {
        //   filebase64str: file.content,
        //   upflag: 2,
        //   moduleno: moduleno,
        //   autoid: autoid,
        //   uploadpath: this.userInfo.attachfilepath,
        //   filename: fileName.substring(0, fileName.lastIndexOf('.')),
        //   fileext,
        //   username: this.userInfo.username
        // }
        let params = {
          moduleno: moduleno,
          upflag: '2',
          autoid: autoid,
          uploadpath: this.userInfo.attachfilepath,
          username: this.userInfo.username,
          file: file.file
        }
        // console.log(this.userInfo.attachfilepath, params)

        await upLoadFile(params).then(res => {})
      }
    },
    preView(val, key, fileList) {
      if (this.fileext.indexOf(val.fileext) > -1) {
        let urlArr = []
        for (let i = 0; i < fileList.length; i++) {
          urlArr.push(fileList[i].url)
        }
        ImagePreview({
          images: urlArr,
          startPosition: key
        })
      } else {
        var u = navigator.userAgent
        var isAndroid = u.indexOf('Android') > -1 || u.indexOf('Adr') > -1 //android终端
        var isiOS = !!u.match(/\(i[^;]+;( U;)? CPU.+Mac OS X/) //ios终端
        if (isAndroid) {
          //android终端
          window.open(val.url, '_blank')
        } else if (isiOS) {
          //ios终端
          window.location.href = val.url
        } else {
          window.open(val.url, '_blank')
        }
      }
    },
    afterRead(file) {
      // console.log(file, 'file');
    },
    showdatepick() {
      this.currentDate = this.yymmdd ? new Date(this.yymmdd) : new Date()
      this.showDtPicker = true
    },
    onCancel() {
      this.showPicker = false
    },
    onDtConfirm(value) {
      this.yymmdd = parseTime(value, '{y}-{m}-{d}')
      this.showDtPicker = false
    },
    // 获取下拉选项（combobox，lookupcombobox）
    comboboxNoispoptreefocus() {
      let tempdata = ['汇报任务', '提交任务']
      this.choices = tempdata
      this.showPicker = true
    },
    onConfirm(value, index) {
      this.checkmemo = value
      this.showPicker = false
    },
    onCancel() {
      this.showPicker = false
    },
    // 弹出下拉树
    showTree() {
      this.showTreePop = true
      this.getCommTree()
    },
    // 获取通用树
    getCommTree(multiseltree) {
      const editstate = 1
      getCommTreeData({
        moduleno: 563,
        treename: 'emptree', // 树名
        username: this.userInfo.username,
        fieldname: 'empname',
        editstate: editstate,
        formstr: ''
      }).then(res => {
        this.allTreeList = res.data
        // 树结构中最小层级(最上层)
        let mini = Math.min.apply(
          Math,
          res.data.map(item => {
            return item.levelid
          })
        )
        if (this.whichTree == 'station' || this.whichTree == 'employee') {
          // 岗位和员工树
          // 初始时的父节点
          this.parentTreeList = res.data.filter(item => {
            return item.levelid == mini
          })
          // 判断父节点有没有下级
          if (this.parentTreeList.length > 0) {
            this.parentTreeList.forEach(el => {
              el.hasLower = false
              for (let i = 0; i < this.allTreeList.length; i++) {
                const it = this.allTreeList[i]
                if (el.keyid == it.parentid) {
                  el.hasLower = true
                  break
                }
              }
            })
          }
          // 初始时的子节点
          this.allTreeList.forEach(el => {
            if (this.parentTreeList.length == 1) {
              // 最大父节点只有一个的时候
              let nodecan = false
              if (this.whichTree == 'station') {
                nodecan = el.issta
              } else if (this.whichTree == 'employee') {
                nodecan = el.isdept == 0 && el.levelid == 16
              }
              if (nodecan && el.parentid == this.parentTreeList[0].keyid) {
                this.childsTreeList.push(el)
              }
            } else {
              // 最大父节点有多个的时候
              this.childsTreeList = []
            }
          })
        } else {
          // 其他树(暂时部门树)
          // 初始时的节点
          this.otherTreeList = res.data.filter(item => {
            return item.levelid == mini
          })
          // 判断初始节点有没有下级
          if (this.otherTreeList.length > 0) {
            this.otherTreeList.forEach(el => {
              el.hasLower = false
              for (let i = 0; i < this.allTreeList.length; i++) {
                const it = this.allTreeList[i]
                if (el.keyid == it.parentid) {
                  el.hasLower = true
                  break
                }
              }
            })
          }
        }
        // console.log(this.allTreeList, 'this.allTreeList')
      })
    },
    // 搜索子节点
    onSearchTree(val) {
      // 清空父节点、子节点和已点击过的父节点
      this.parentTreeList = []
      this.childsTreeList = []
      this.clickParentTree = []
      this.otherTreeList = []
      this.allTreeList.forEach(el => {
        let nodecan = false
        if (this.whichTree == 'station') {
          nodecan = el.issta
        } else if (this.whichTree == 'employee') {
          nodecan = el.isdept == 0 && el.levelid == 16
        }
        // 岗位和人员
        if (this.whichTree == 'station' || this.whichTree == 'employee') {
          if (nodecan && el.keyname.indexOf(val) !== -1) {
            this.childsTreeList.push(el)
          }
        } // 其他（部门）
        else {
          if (el.keyname.indexOf(val) !== -1) {
            // 判断节点有没有下级
            let lowerAry = this.allTreeList.filter(it => {
              return it.parentid == el.keyid
            })
            if (lowerAry.length > 0) {
              el.hasLower = true
            } else {
              el.hasLower = false
            }
            this.otherTreeList.push(el)
          }
        }
      })
    },
    // 回到某个树节点
    backTo(val) {
      if (val === true) {
        // 点击的开始,回到最初始状态
        this.parentTreeList = []
        this.childsTreeList = []
        this.clickParentTree = []
        this.otherTreeList = []
        this.searchTreeVal = ''
        this.allTreeList = []
        this.getCommTree(this.multiseltree)
      } else {
        // 否则先在clickParentTree去掉该父节点及节点后所有节点
        let ary = [] // 记录该节点及后的所有节点
        for (let i = 0; i < this.clickParentTree.length; i++) {
          const ele = this.clickParentTree[i]
          if (val.keyid == ele.keyid) {
            // 如果是数组最后一个则不可以点击，数据不变
            if (i == this.clickParentTree.length - 1) {
              return false
            } else {
              ary = this.clickParentTree.filter((item, index) => {
                return index < i
              })
            }
          }
        }
        this.clickParentTree = ary
        // 去掉之后再区分（岗位和员工）和其他树
        if (this.whichTree == 'station' || this.whichTree == 'employee') {
          // 岗位和员工树
          // 再获取该父节点下的子节点
          this.checkParent(val)
        } else {
          // 其他树
          this.hasLower(val)
        }
      }
    },
    // 其他树点击下级
    hasLower(val) {
      // 储存点击过的有下级的父节点
      if (val.hasLower) {
        this.clickParentTree.push(val)
      }
      // 获取此节点下所有子节点
      let array = this.allTreeList.filter(item => {
        return item.parentid == val.keyid
      })
      if (array.length > 0) {
        this.otherTreeList = []
        array.forEach(item => {
          // 判断节点有没有下级
          let lowerAry = this.allTreeList.filter(it => {
            return it.parentid == item.keyid
          })
          if (lowerAry.length > 0) {
            item.hasLower = true
          } else {
            item.hasLower = false
          }
          // 父节点
          this.otherTreeList.push(item)
        })
      }
    },
    // 点击树的父节点(针对岗位和员工树)
    checkParent(val) {
      // 储存点击过的有下级的父节点
      if (val.hasLower) {
        this.clickParentTree.push(val)
      }
      // 获取此父节点下所有节点
      let array = this.allTreeList.filter(item => {
        return item.parentid == val.keyid
      })
      // 遍历所有节点并分出父节点和子节点
      if (array.length > 0) {
        this.parentTreeList = []
        this.childsTreeList = []
        array.forEach(item => {
          let nodecan = false
          if (this.whichTree == 'station') {
            nodecan = item.issta
          } else if (this.whichTree == 'employee') {
            nodecan = item.isdept == 0 && item.levelid == 16
          }
          if (nodecan) {
            // 子节点
            this.childsTreeList.push(item)
          } else {
            // 判断父节点有没有下级
            let lowerAry = this.allTreeList.filter(it => {
              return it.parentid == item.keyid
            })
            if (lowerAry.length > 0) {
              item.hasLower = true
            } else {
              item.hasLower = false
            }
            // 父节点
            this.parentTreeList.push(item)
          }
        })
      }
    },
    // 选择父节点复选框
    tickParent(item) {
      // 判断父节点有没有下级
      let lowerAry = this.allTreeList.filter(it => {
        return it.parentid == item.keyid
      })
      if (lowerAry.length > 0) {
        if (item.checked == true) {
          lowerAry.forEach(ele => {
            this.allTreeList.map(it => {
              if (it.keyid == ele.keyid) {
                it.checked = true
              }
            })
            this.tickParent(ele)
          })
        } else {
          lowerAry.forEach(ele => {
            this.allTreeList.map(it => {
              if (it.keyid == ele.keyid) {
                it.checked = false
              }
            })
            this.tickParent(ele)
          })
        }
      }
    },
    // 多选树清除复选框选中
    cleanChecked() {
      this.allTreeList.forEach(item => {
        item.checked = false
      })
    },
    // 多选树确认复选框选中
    comfirmChecked() {
      let array = this.allTreeList.filter(ele => {
        return ele.checked == true
      })
      let trueList = []
      if (array.length > 0) {
        if (this.whichTree == 'station') {
          for (let i = 0; i < array.length; i++) {
            const it = array[i]
            if (it.issta == true) {
              trueList.push(it)
            }
          }
        } else if (this.whichTree == 'employee') {
          for (let l = 0; l < array.length; l++) {
            const item = array[l]
            if (item.isdept == 0 && item.levelid == 16) {
              trueList.push(item)
            }
          }
        } else {
          // 其他树
          trueList = array
        }
      }
      this.checkChild(trueList)
      // console.log(trueList, 'trueListtrueList')
    },
    // 点击树的子节点(暂时单选树)(针对岗位和员工树)
    checkChild(it) {
      let checked = []
      checked = [it]
      this.empname = checked[0].keyname
      this.empid = checked[0].empid
      // 最终关闭弹窗
      this.showTreePop = false
    }
  }
}
</script>

<style lang="less" scoped>
.ApprovalTag {
  height: 100vh;
  overflow: hidden;
  .van-nav-bar {
    background: #2b8df0;

    /deep/ .van-nav-bar__left {
      .van-icon {
        color: #fff;
      }
    }
    /deep/.van-nav-bar__title {
      color: #fff;
      font-size: 36px;
      font-family: Source Han Sans CN;
    }
  }
  .content {
    // overflow: auto;
    margin-top: 1.22667rem;
    height: calc(100% - 92px);
    /deep/.van-tabs {
      .van-tab {
        .van-tab__text {
          font-size: 32px;
          font-family: Source Han Sans CN;
          font-weight: bold;
        }
      }
    }
    .van-pull-refresh {
      margin-top: 30px;
      overflow: auto;
      height: calc(100% - 120px);
    }
    .KPITagRefresh {
      margin-top: 30px;
      overflow: auto;
      height: calc(100% - 220px);
    }

    .zb_group {
      .ass_cell {
        margin: 10px 0;
        .van-cell__title {
          flex: 1;
          font-size: 32px;
          font-family: Source Han Sans CN;
          font-weight: 500;
          line-height: 54px;
          color: #333333;
        }
        .filelist {
          display: flex;
          .van-form_file_List {
            width: 25%;
            margin: 15px;
            img {
              width: 100%;
            }
          }
        }
      }
    }
  }
  .footer_btn {
    width: 100%;
    padding: 0.26667rem 0;
    position: fixed;
    bottom: 0;
    left: 0;
    z-index: 100;
    display: flex;
    background-color: #fff;
    .van-button {
      width: 100%;
      margin: 0 2%;
      border-radius: 9px;
      // width: 3.86667rem;
      .van-button__text {
        font-size: 0.48rem;
        font-family: Source Han Sans CN;
        font-weight: 500;
      }
    }
  }
  .tree_popup {
    z-index: 6666 !important;
    .tree_title {
      height: 90px;
      text-align: center;
      span {
        font-size: 36px;
        font-weight: bold;
      }
    }
    .tree_search {
      height: 100px;
    }
    .tree_head {
      height: 90px;
      white-space: nowrap;
      overflow-x: auto;
      display: flex;
      align-items: center;
      span {
        font-size: 32px;
      }
      .theLast {
        color: #969799;
        margin-right: 10px;
      }
      .noLast {
        color: #1491f7;
      }
      .head_start {
        color: #1491f7;
        padding: 0 10px 0 20px;
      }
      .division {
        color: #969799;
        padding: 0 16px;
      }
    }
    .van-divider::before {
      border-width: 20px 0 0;
    }

    .tree_body {
      height: calc(100% - 5.7rem);
      overflow: auto;
      .parents_cell {
        .van-checkbox {
          padding-right: 10px;
        }
        .van-cell__title {
          flex: 1 1 83%;
          display: flex;
          align-items: center;
        }
        .van-cell__value {
          flex: 1 1 17%;
          div {
            display: flex;
            align-items: center;
            justify-content: end;
            border-left: 1px solid #969799;
          }
          .haveClo {
            color: #1491f7;
          }
          .noClo {
            color: #969799;
          }
          .van-icon {
            padding: 0 10px;
          }
        }
      }
      .childs_cell,
      .other_cell {
        .van-cell__title {
          display: flex;
          align-items: center;
          .van-button {
            border-radius: 12px;
            font-size: 28px;
          }
          .check_name {
            padding-left: 10px;
          }
        }
      }
    }
    .tree_foot {
      position: absolute;
      bottom: 0;
      width: 100%;
      background: #fff;
      display: flex;
      justify-content: space-evenly;
      .van-button {
        width: 30vw;
        margin-bottom: 10px;
      }
    }

    /deep/.van-popup__close-icon--top-left {
      top: 25px !important;
      left: 10px !important;
    }
  }
  .van-slider {
    height: 26px;
    .custom-button {
      width: 26px;
      // height: 26px;
      color: #fff;
      font-size: 10px;
      line-height: 18px;
      text-align: center;
      background-color: #1989fa;
      border-radius: 100px;
    }
  }
}
</style>
